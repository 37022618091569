<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-search</v-icon>配送先一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch">
            <v-text-field
              v-model="searchModel.deliveryCode"
              label="配送先CD"
              :rules="[rules.isCode, rules.maxLength(12)]"
              dense
            ></v-text-field>
            <v-text-field v-model="searchModel.groupName" label="取引先名" v-if="isCafereoUser" dense></v-text-field>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical class="mx-2"></v-divider>
        <tooltip-icon-button
          v-if="allowedAction('V030402', 'C070402')"
          icon="mdi-pencil-plus-outline"
          @click="onBtnInsert"
          >新規登録</tooltip-icon-button
        >
        <tooltip-icon-button v-if="allowedAction('C070406')" icon="mdi-database-import" @click="onBtnImport"
          >一括取込</tooltip-icon-button
        >
        <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
          <delivery-destination-import
            @complete="onImportComplete"
            @expand="importDialog.width = $event ? '100%' : '800px'"
            v-if="importDialog.show"
          ></delivery-destination-import>
        </v-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <tooltip-icon-button
          v-if="allowedAction('V030403', 'C070403')"
          :disabled="!selectedRow || vendorDisabledRule"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <delivery-destination-entry
            ref="deliveryDestinationEntry"
            :inputModel="deliveryModel"
            @onDialogClose="onDialogClose"
            @onEntrySubmit="onEntrySubmit"
            @onEditSubmit="onEditSubmit"
            v-if="editDialog.show"
          ></delivery-destination-entry>
        </v-dialog>
        <tooltip-icon-button
          v-if="allowedAction('C070404')"
          :disabled="selectionRows.length === 0 || vendorDisabledRule"
          icon="mdi-trash-can-outline"
          @click="onBtnDelete"
          >削除</tooltip-icon-button
        >
        <tooltip-icon-button v-if="allowedAction('V030405', 'C070405')" icon="mdi-download" @click="onBtnExport">
          CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical class="mx-2"></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="DeliveryDestinationList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.deliveryCode"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="DeliveryDestinationInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <delivery-destination-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :deliveryCode="selectedRow != null ? selectedRow.deliveryCode : null"
        ></delivery-destination-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { statuses as ApiStatus } from "../../libs/api-client";
import DeliveryDestinationInfos from "../../components/deliveryDestination/DeliveryDestinationInfos.vue";
import DeliveryDestinationEntry from "../../components/deliveryDestination/DeliveryDestinationEntry.vue";
import DeliveryDestinationImport from "../../components/deliveryDestination/DeliveryDestinationImport.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import { FullDateColumn, CheckmarkColumn, DateColumn, IncludeFilter } from "../../models/ag-grid/columnTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import DeliveryStatus from "../../consts/DeliveryStatus";
import SlipIssuanceType from "../../consts/SlipIssuanceType";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import Validation from "../../libs/validation";

export default {
  name: "DeliveryDestinationSearch",
  components: {
    AgGridVue,
    DeliveryDestinationInfos,
    DeliveryDestinationEntry,
    DeliveryDestinationImport,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ErrorGridDialog,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      gridApi: null,
      columnApi: null,
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isCode: Validation.isCode,
      },
      defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      constants: {
        cafereoActions: ["C070402", "C070403"],
      },
      columnDefs: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
      ],
      defaultCsvExportParams: {
        allColumns: false,
        onlySelectedAllPages: false,
      },
      rowData: [],
      detailSelected: false,
      infoMaximum: false,
      selectedPageSize: null,
      localeText: AG_GRID_LOCALE_JA,
      selectionRows: [],
      searchModel: {
        deliveryCode: "",
        groupName: "",
        pauseDisplayFlg: true,
      },
      editDialog: { show: false, width: "800px" },
      importDialog: { show: false, width: "800px" },
      deliveryModel: {
        createNew: false,
        deliveryCode: null,
        groupCode: null,
        groupName: null,
        groupZipCode: null,
        groupAddress: null,
        groupTelNumber: null,
        deliveryZipCode: null,
        delivery1: null,
        delivery2: null,
        deliveryAddress: null,
        deliveryPhoneNumber: null,
        deliveryPlace: null,
        routeDelivery: null,
      },
      columnTypes: {
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      cafereoColumnDefs: [
        {
          headerName: "ステータス",
          field: "deliveryStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: DeliveryStatus.all() },
          valueGetter: (params) => DeliveryStatus.of(params.data.deliveryStatus),
        },
        { headerName: "社店CD", field: "groupCode" },
        { headerName: "取引先名", field: "groupName" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "配送先〒", field: "deliveryZipCode" },
        { headerName: "届先法人名", field: "delivery1" },
        { headerName: " 届先名称", field: "delivery2", filterParams: IncludeFilter },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "配送先TEL", field: "deliveryPhoneNumber" },
        {
          headerName: "ルート配送",
          field: "routeDelivery",
          type: "dpCheckmarkColumn",
          filterParams: { caption: { true: "ルート配送", false: "通常配送" } },
        },
        {
          headerName: "伝票発行区分",
          field: "slipIssuance_type",
          filter: "dpSelectionFilter",
          filterParams: { options: SlipIssuanceType.all() },
          valueGetter: (params) => SlipIssuanceType.of(params.data.slipIssuance_type),
        },
        {
          headerName: "倉庫間移動出荷",
          field: "transferWarehouses",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "配送先所要日数",
          field: "daysRequired",
          valueGetter: (params) => {
            if (params.data.daysRequired == 1) {
              return "翌日着";
            } else if (params.data.daysRequired > 1) {
              const daysParams = params.data.daysRequired - 1;
              return `中${daysParams}日着`;
            }
            return "";
          },
        },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      vendorColumnDefs: [
        {
          headerName: "ステータス",
          field: "deliveryStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: DeliveryStatus.all() },
          valueGetter: (params) => DeliveryStatus.of(params.data.deliveryStatus),
        },
        { headerName: "社店CD", field: "groupCode" },
        { headerName: "店舗名", field: "groupName" },
        { headerName: "配送先CD", field: "deliveryCode" },
        { headerName: "配送先〒", field: "deliveryZipCode" },
        { headerName: "届先法人名", field: "delivery1" },
        { headerName: " 届先名称", field: "delivery2", filterParams: IncludeFilter },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "配送先TEL", field: "deliveryPhoneNumber" },
      ],
    };
  },
  beforeMount() {
    this.selectedPageSize = this.globalPageSize;
    if (this.isCafereoUser) this.columnDefs = this.columnDefs.concat(this.cafereoColumnDefs);
    if (this.isVendorUser) this.columnDefs = this.columnDefs.concat(this.vendorColumnDefs);
  },
  async mounted() {
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    console.log("this.gridHeightSize" + this.gridHeightSize);
    this.gridStyle.height = this.gridHeightSize - 10 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.deliveryCode, this.gridApi, this.columnApi);
    },
    vendorDisabledRule() {
      return (
        this.isVendorUser &&
        this.selectionRows.some((r) => r.groupCode !== this.$store.getters["security/loggedInUser"].groupCode)
      );
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv({
        allColumns: false,
        onlySelected: this.selectionRows.length > 0,
        fileName: `配送先一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    onBtnInsert() {
      this.deliveryModel = {
        createNew: true,
        corporationCd: this.allowedAction(this.constants.cafereoActions)
          ? null
          : this.$store.getters["security/loggedInUser"].corporateCode,
        corporationType: null,
        deliveryCode: null,
        deliveryStatus: null,
        groupCode: this.allowedAction(this.constants.cafereoActions)
          ? null
          : this.$store.getters["security/loggedInUser"].groupCode,
        groupName: null,
        deliveryZipCode: null,
        delivery1: null,
        delivery2: null,
        deliveryAddress: null,
        deliveryPhoneNumber: null,
        slipIssuance_type: null,
        routeDelivery: false,
        transferWarehouses: false,
        updateDatetime: null,
      };
      this.editDialog.show = true;
    },
    onBtnUpdate() {
      this.deliveryModel = {
        createNew: false,
        corporationCd: this.allowedAction(this.constants.cafereoActions)
          ? this.selectedRow.corporationCd
          : this.$store.getters["security/loggedInUser"].corporateCode,
        corporationType: this.selectedRow.corporationType,
        deliveryCode: this.selectedRow.deliveryCode,
        deliveryStatus: this.selectedRow.deliveryStatus,
        groupCode: this.selectedRow.groupCode,
        groupName: this.selectedRow.groupName,
        deliveryZipCode: this.selectedRow.deliveryZipCode,
        delivery1: this.selectedRow.delivery1,
        delivery2: this.selectedRow.delivery2,
        deliveryAddress: this.selectedRow.deliveryAddress,
        deliveryPhoneNumber: this.selectedRow.deliveryPhoneNumber,
        slipIssuance_type: this.selectedRow.slipIssuance_type,
        routeDelivery: this.selectedRow.routeDelivery,
        transferWarehouses: this.selectedRow.transferWarehouses,
        updateDatetime: this.selectedRow.updateDatetime,
      };
      this.editDialog.show = true;
    },
    async onBtnDelete() {
      try {
        this.loadingOn();
        const messageText = `選択された配送先情報を削除します <small>(${this.selectionRows.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: "配送先一覧", text: messageText });
        if (ok) {
          const deliveries = {
            deliveries: this.selectionRows.map((r) => ({
              deliveryCode: r.deliveryCode,
              updateDatetime: r.updateDatetime,
            })),
          };

          const result = await this.$store.dispatch("deliveryDestination/remove", deliveries);
          let errorMsg = {};
          let tmpErrorMsg = {};
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridApi.applyTransaction({ remove: this.selectionRows });
              this.$dialog.notify.info(`配送先情報が削除されました (${this.selectionRows.length}件)`, {
                timeout: 2300,
              });
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              tmpErrorMsg = this.selectionRows.map(function (r) {
                let tmp = null;
                if (result.data.header.messages[r.deliveryCode]) {
                  tmp = {
                    deliveryCode: r.deliveryCode,
                    errorMsg: result.data.header.messages[r.deliveryCode]?.join(),
                  };
                }
                return tmp;
              });
              errorMsg = tmpErrorMsg.filter((r) => r != null);

              this.$refs.errorGrid.open({
                title: "エラーメッセージ",
                columns: [
                  {
                    headerName: "配送先CD",
                    field: "deliveryCode",
                    pinned: "left",
                  },
                  {
                    headerName: "エラーメッセージ",
                    field: "errorMsg",
                  },
                ],
                records: errorMsg,
                onClose: async () => {
                  this.selectionRows = [];
                  this.onSearchClick();
                },
              });
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("DeliveryDestinationSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("deliveryDestination/searchDelivery", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "配送先一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.deliveries).length === 0) {
          this.$dialog.warning({
            title: "配送先一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(response.data.contents.deliveries);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("DeliverySearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    async onEntrySubmit(delivery) {
      try {
        this.loadingOn();
        this.editDialog.show = false;
        this.$dialog.notify.info(`配送先情報を登録しました (${delivery.deliveryCode})`, { timeout: 2300 });
        this.onSearchClick();
      } catch (error) {
        console.error("DeliveryDestinationSearch::onEntrySubmit", error);
        this.loadingOff();
        this.apiRequestError(error);
      }
    },
    async onEditSubmit(updateModel) {
      try {
        this.selectedRow.corporationCd = updateModel.corporationCd;
        this.selectedRow.corporationType = updateModel.corporationType;
        this.selectedRow.deliveryCode = updateModel.deliveryCode;
        this.selectedRow.deliveryStatus = updateModel.deliveryStatus;
        this.selectedRow.groupCode = updateModel.groupCode;
        this.selectedRow.groupName = updateModel.groupName;
        this.selectedRow.deliveryZipCode = updateModel.deliveryZipCode;
        this.selectedRow.delivery1 = updateModel.delivery1;
        this.selectedRow.delivery2 = updateModel.delivery2;
        this.selectedRow.deliveryAddress = updateModel.deliveryAddress;
        this.selectedRow.deliveryPhoneNumber = updateModel.deliveryPhoneNumber;
        this.selectedRow.slipIssuance_type = updateModel.slipIssuance_type;
        this.selectedRow.routeDelivery = updateModel.routeDelivery;
        this.selectedRow.transferWarehouses = updateModel.transferWarehouses;
        this.selectedRow.updateDatetime = updateModel.updateDatetime;
        this.gridApi.applyTransaction({ update: this.selectionRows });
        this.editDialog.show = false;
        this.$dialog.notify.info(`配送先情報を更新しました (${updateModel.deliveryCode})`, { timeout: 2300 });
      } catch (error) {
        console.error("DeliveryDestinationSearch::onEditSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnImport() {
      this.importDialog.show = true;
    },
    onImportComplete(canceled) {
      this.importDialog.show = false;
      if (canceled) {
        this.onSearchClick();
      }
    },
  },
};
</script>
