<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>取引先一括編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="customerBulkEditForm" lazy-validation>
        <v-container>
          <section-block>
            <v-row v-for="(source, index) in settingSources" :key="index" dense>
              <v-col cols="3">
                <v-select
                  :items="constant.customerBulkEditColumnsValues"
                  label="設定項目"
                  v-model="source.column"
                  :rules="[rules.required, rules.validSelectColumn]"
                  @change="onSettingColSelected(index, source.column)"
                  dense
                  filled
                  attach
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.CUSTOMER_NAME"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(150)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.ZIP_CODE"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.zipCode]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.PREFECTURE"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.required, rules.maxLength(4)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.LOCATION_ADDRESS"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.required, rules.maxLength(150)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.LOCATION_BUILDING"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(150)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.TEL_NUMBER"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.phoneNo]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.FAX_NUMBER"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.phoneNo]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.CUSTOMER_STAFF"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(150)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.IN_COMPANY_ORDER_MAX_AMOUNT"
                  label="設定値"
                  v-model="source.value"
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.maxLength(13)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-select
                  v-if="source.column === constant.customerBulkEditColumns.CUSTOMER_STATUS"
                  v-model="source.value"
                  :items="constant.customerStatus"
                  label="設定値"
                  dense
                  filled
                  attach
                ></v-select>
                <v-select
                  v-if="source.column === constant.customerBulkEditColumns.SHIPMENT_DAY_OF_WEEK"
                  v-model="source.value"
                  :items="constant.shipmentDayOfWeek"
                  label="設定値"
                  multiple
                  dense
                  filled
                  attach
                ></v-select>
                <v-switch
                  v-if="source.column === constant.customerBulkEditColumns.USE_ORICON"
                  v-model="source.value"
                  inset
                  dense
                ></v-switch>
                <v-switch
                  v-if="source.column === constant.customerBulkEditColumns.FORECAST"
                  v-model="source.value"
                  inset
                  dense
                ></v-switch>
                <v-switch
                  v-if="source.column === constant.customerBulkEditColumns.AUTO_SHIP_REQUEST"
                  v-model="source.value"
                  inset
                  dense
                ></v-switch>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.REMARKS"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(100)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.WAREHOUSE_REMARKS"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(150)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-switch
                  v-if="source.column === constant.customerBulkEditColumns.INVOICE_APPROVAL"
                  v-model="source.value"
                  inset
                  dense
                ></v-switch>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.INVOICE_ZIP_CODE"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.zipCode]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.INVOICE_ADDRESS"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(150)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.INVOICE_PHONE_NUMBER"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.phoneNo]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.INVOICE_STAFF"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(150)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.customerBulkEditColumns.INVOICE_MAILING_ADDRESS"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(150)]"
                  filled
                  dense
                >
                </v-text-field>
                <cafereo-user-field
                  ref="salesStaff"
                  v-if="source.column === constant.customerBulkEditColumns.OUR_SALES_STAFF"
                  label="カフェレオ担当者"
                  v-model="source.value"
                  :attach="false"
                  @selected="onCustomerPersonChanged"
                ></cafereo-user-field>
                <cafereo-users-field
                  ref="salesAssistant"
                  v-if="source.column === constant.customerBulkEditColumns.ASSISTANT"
                  label="担当AS"
                  :attach="false"
                  v-model="source.value"
                  @selected="onCustomerPersonChanged"
                ></cafereo-users-field>
                <v-switch
                  v-if="source.column === constant.customerBulkEditColumns.JAN_DISPLAY"
                  v-model="source.value"
                  inset
                  dense
                ></v-switch>
              </v-col>
              <v-col cols="1"
                ><v-btn v-if="index != 0" x-small fab dense @click="onDelSettingSource(index)">
                  <v-icon dark> mdi-minus </v-icon></v-btn
                ></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-btn x-small fab dense @click="onAddSettingSource">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </section-block>
          <v-divider class="mt-3 pt-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button
                :disabled="!selectedRows.length > 0"
                icon="mdi-arrow-down-bold-outline"
                @click="onBtnSetUpdate"
                >一括反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12" style="height: 450px">
              <ag-grid-vue
                class="ag-theme-alpine"
                style="height: 100%"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :pagination="true"
                :suppressColumnVirtualisation="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                :enableCellTextSelection="true"
                @grid-ready="onGridReady"
                @selection-changed="onSelectionChanged"
                @column-everything-changed="onColumnEverythingChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.customerCode"
                :frameworkComponents="frameworkComponents"
                @cell-value-changed="onCellValueChanged"
                @cell-editing-started="onCellEditingStarted"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="取引先一括編集"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCheckBoxColumn,
} from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import SectionBlock from "../common/SectionBlock.vue";
import {
  DateCellEditor,
  CheckboxCellEditor,
  CustomDateCellEditor,
  AboutDateCellEditor,
  NumericCellEditor,
  MultiSelectCellEditor,
  AutoCompleteCellEditor,
} from "../../components/ag-grid/cellEditors";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import moment from "moment";
import {} from "../../components/ag-grid/cellEditors";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import customerBulkEditColumns from "../../consts/editColumns/CustomerBulkEditColumns";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import ConvertUtils from "../../utils/ConvertUtils";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";

import MasterStatuses from "../../consts/CustomerStatus";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import { VendorPaymentTermsClosingDate } from "../../consts/PaymentTermsClosingDate";
import { VendorPaymentTermsPaymentDate } from "../../consts/PaymentTermsPaymentDate";
import ShipmentDayOfWeek from "../../consts/ShipmentDayOfWeek";
import CustomerStatus from "../../consts/CustomerStatus";
import JanDisplay from "../../consts/JanDisplay";
import CafereoUsersField from "../../components/common/fields/CafereoUsersField.vue";
import CommonUtils from "../../utils/CommonUtils";

export default {
  name: "CustomerBulkEdit",
  props: ["inputModel", "userInfos"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
    AboutDateCellEditor,
    NumericCellEditor,
    MultiSelectCellEditor,
    AutoCompleteCellEditor,
    SectionBlock,
    CafereoUserField,
    CafereoUsersField,
  },
  data() {
    const checkStatuses = { true: "✔", false: "　" };
    const userInfos = this.userInfos;
    //const abc = ["this"];
    return {
      updateModel: {},
      defaultModel: {
        shipDate: null,
        specifiedDeliveryDate: null,
        isReady: null,
        cartonNumber: null,
        useSagawa: null,
        useCharter: null,
        priorityAm: null,
        useOricon: null,
        gachaProducts: null,
        bundledDesignation: null,
        preShipment: null,
        cashOnDelivery: null,
        useShippingLabel: null,
        enhancedInspection: null,
        remarks: null,
      },
      constant: {
        customerBulkEditColumnsValues: customerBulkEditColumns.all(),
        customerBulkEditColumns: customerBulkEditColumns,
        deliveryCompanyList: DeliveryCompanys.all(),
        shipmentDayOfWeek: ShipmentDayOfWeek.all(),
        customerStatus: CustomerStatus.all(),
        janDisplay: JanDisplay.all(),
      },
      hideAbout: ["pending"],
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: [],
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      settingSources: [],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableColumn: EditableColumn,
        dpEditableDateColumn: EditableDateColumn,
        dpEditableCheckBoxColumn: EditableCheckBoxColumn,
      },
      frameworkComponents: {
        dpDateCellEditor: DateCellEditor,
        dpCheckboxCellEditor: CheckboxCellEditor,
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpCellDatePicker: CustomDateCellEditor,
        dpCellAboutDatePicker: AboutDateCellEditor,
        dpNumericCellEditor: NumericCellEditor,
        dpMultiSelectCellEditor: MultiSelectCellEditor,
        dpAutoCompleteCellEditor: AutoCompleteCellEditor,
      },
      cafereoColumnDefs: [
        { headerName: "社店CD", field: "customerCode", pinned: "left" },
        {
          headerName: "ステータス",
          field: "customerStatus",
          editable: true,
          filter: "dpSelectionFilter",
          filterParams: { options: MasterStatuses.all() },
          cellEditor: "agSelectCellEditor",
          cellEditorPopup: true,
          cellEditorParams: {
            values: Object.keys(MasterStatuses.refDataArray()),
            cellEditorPopup: true,
          },
          refData: MasterStatuses.refDataArray(),
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateCustomerStatus) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "担当部署",
          field: "salesDepartment",
        },
        {
          headerName: "営業担当",
          field: "ourSalesStaffCd",
          valueFormatter: (params) => {
            let ret = null;
            console.log(params);
            if (params.value) {
              const item = this.userInfos.find((v) => v.value === params.value);
              return item ? item.label : "";
            } else {
              ret = params.value;
            }
            return ret;
          },
          editable: true,
          cellEditorFramework: "AutoCompleteCellEditor",
          cellEditorParams: {
            cellEditorItems: userInfos,
            cellEditorValueId: "value",
            cellEditorTextId: "label",
            cellEditorMultiple: false,
          },
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateOurSalesStaff) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "担当AS",
          field: "assistantCd",
          valueFormatter: (params) => {
            let ret = null;
            if (Array.isArray(params.value)) {
              const self = this;
              ret = params.value.map(function (elem) {
                const item = self.userInfos.find((v) => v.value === elem);
                return item ? item.label : "";
              });
            } else {
              ret = params.value;
            }
            return ret;
          },
          editable: true,
          cellEditorFramework: "AutoCompleteCellEditor",
          cellEditorParams: {
            cellEditorItems: userInfos,
            cellEditorValueId: "value",
            cellEditorTextId: "label",
            cellEditorMultiple: true,
          },
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateAssistant) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "法人CD", field: "corporateCode" },
        { headerName: "法人名", field: "corporateName" },
        {
          headerName: "取引先名",
          field: "customerName",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateCustomerName) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "郵便番号",
          field: "zipCode",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateZipCode) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "都道府県",
          field: "prefecture",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updatePrefecture) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "所在地（住所）",
          field: "locationAddress",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateLocationAddress) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "所在地（ビル名）",
          field: "locationBuilding",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateLocationBuilding) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "電話番号",
          field: "telNumber",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateTelNumber) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "FAX番号",
          field: "faxNumber",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateFaxNumber) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "担当者",
          field: "customerStaff",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateCustomerStaff) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "社内発注上限金額",
          field: "inCompanyOrderMaxAmount",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          editable: true,
          valueFormatter: NumberValueFormatter(),
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInCompanyOrderMaxAmount) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "支払条件締日",
          field: "paymentTermsClosingDate",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorPaymentTermsClosingDate.all() },
          valueGetter: (params) => VendorPaymentTermsClosingDate.of(params.data.paymentTermsClosingDate),
        },
        {
          headerName: "支払条件支払日",
          field: "paymentTermsPaymentDate",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorPaymentTermsPaymentDate.all() },
          valueGetter: (params) => VendorPaymentTermsPaymentDate.of(params.data.paymentTermsPaymentDate),
        },

        {
          headerName: "出荷曜日指定",
          field: "shipmentDayOfWeek",
          filterParams: { options: ShipmentDayOfWeek.all() },
          valueFormatter: (params) => {
            return params.data.shipmentDayOfWeek.map(function (elem) {
              return ShipmentDayOfWeek.of(elem);
            });
          },
          editable: true,
          cellEditorFramework: "MultiSelectCellEditor",
          cellEditorParams: {
            cellEditorItems: ShipmentDayOfWeek.all(),
          },
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateShipmentDayOfWeek) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "オリコン指定",
          field: "useOricon",
          filter: "dpBooleanFilter",
          filterParams: { caption: checkStatuses },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: Object.keys(checkStatuses),
            cellEditorPopup: true,
          },
          refData: checkStatuses,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateUseOricon) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "フォーキャスト可否",
          field: "forecast",
          filter: "dpBooleanFilter",
          filterParams: { caption: checkStatuses },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: Object.keys(checkStatuses),
            cellEditorPopup: true,
          },
          refData: checkStatuses,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateForecast) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "自動出荷希望",
          field: "autoShipRequest",
          filter: "dpBooleanFilter",
          filterParams: { caption: checkStatuses },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: Object.keys(checkStatuses),
            cellEditorPopup: true,
          },
          refData: checkStatuses,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateAutoShipRequest) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "請求書発行可否",
          field: "invoiceApproval",
          filter: "dpBooleanFilter",
          filterParams: { caption: checkStatuses },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: Object.keys(checkStatuses),
            cellEditorPopup: true,
          },
          refData: checkStatuses,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInvoiceApproval) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "請求先〒",
          field: "invoiceZipCode",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInvoiceZipCode) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "請求先住所",
          field: "invoiceAddress",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInvoiceAddress) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "請求先TEL",
          field: "invoicePhoneNumber",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInvoicePhoneNumber) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "請求担当者",
          field: "invoiceStaff",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInvoiceStaff) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "請求書宛名",
          field: "invoiceMailingAddress",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInvoiceMailingAddress) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "取引開始年月日",
          field: "transactionStartDate",
          type: "dpDateColumn",
        },
        {
          headerName: "備考",
          field: "remarks",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateRemarks) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "倉庫備考",
          field: "warehouseRemarks",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateWarehouseRemarks) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "JAN表示",
          field: "janDisplay",
          editable: true,
          filter: "dpSelectionFilter",
          filterParams: { options: JanDisplay.all() },
          cellEditor: "agSelectCellEditor",
          cellEditorPopup: true,
          cellEditorParams: {
            values: Object.keys(JanDisplay.refDataArray()),
            cellEditorPopup: true,
          },
          refData: JanDisplay.refDataArray(),
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateJanDisplay) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
      ],

      errorColmuns: [
        { headerName: "社店CD", field: "customerCode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
      stringToNumber: ConvertUtils.stringToNumber,
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        rangeOfNumber: Validation.rangeOfNumber,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
        isDeliveryZipArea: Validation.isDeliveryZipArea,
        validSelectColumn: (value) => this.validSelectColumn(value),
      },
      //customerPersonList: [],
      customerPersonNames: "",
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    // ****************************
    // ag-grid定義
    // ****************************
    async setColumnDefs() {
      // カフェレオ担当者サジェスト
      // ※※ag-gridのカスタムセルとのスコープ問題が解決できなかったのでこのタイミングでの取得では間に合わない
      // ※※上位modからpropで渡すようにしている。
      // try {
      //   this.customerPersonList = [];
      //   const userResponse = await this.$store.dispatch("cafereoUser/search");
      //   if (userResponse.data?.contents?.users) {
      //     var users = userResponse.data.contents.users;
      //     for (let i = 0; i < users.length; i++) {
      //       const user = users[i];
      //       const customerPersonInfo = {
      //         value: user.userId,
      //         label: user.userName,
      //       };
      //       this.customerPersonList.push(customerPersonInfo);
      //     }
      //   }
      // } catch (error) {
      //   console.error("customerBulkEdit::setColumnDefs cafereoUser/search", error);
      //   this.apiRequestError(error);
      // }

      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
      ];
      columnDefs = columnDefs.concat(this.cafereoColumnDefs);
      // ag-grid内のvalidation等をセット
      //getColumnDef(columnDefs, "ourSalesStaffCd").cellEditorParams = this.cellEditorCustomerPersonName(); // カフェレオ担当者サジェスト
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.columnApi?.setColumnWidth("ourSalesStaffCd", 200);
      this.columnApi?.setColumnWidth("assistantCd", 250);
      this.gridApi.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    onCellEditingStarted(event) {
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
    onColumnEverythingChanged() {
      var allColumnIds = [];
      this.columnApi?.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi?.autoSizeColumns(allColumnIds);
      this.columnApi?.setColumnWidth("ourSalesStaffCd", 200);
      this.columnApi?.setColumnWidth("assistantCd", 250);
      this.gridApi?.refreshCells();
    },
    // ****************************
    // 画面アクション定義
    // ****************************
    init(inputModel) {
      this.updateModel = this.defaultModel;
      this.gridApi.setRowData(JSON.parse(JSON.stringify(inputModel)));
      this.updateSuccessRecords = [];
      // 初期表示時、設定項目を１つ表示する。
      this.onAddSettingSource();
      if (this.$refs.customerBulkEditForm) {
        this.$refs.customerBulkEditForm.resetValidation();
      }
    },
    reset() {
      this.init(this.inputModel);
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("onDialogClose", this.updateSuccessRecords);
    },
    async onUpdateClick() {
      this.loadingOn();
      var requestRecords = [];
      var tmpRequestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.gridApi.forEachNode(async (record) => {
        tmpRequestRecords.push(record.data);
      });
      for (let record of tmpRequestRecords) {
        var error = await this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            customerCode: record.customerCode,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      }
      if (isValid) {
        try {
          var param = {
            customers: requestRecords,
          };
          console.log(param);
          const response = await this.$store.dispatch("customer/bulkupdate", param);
          let error = response.data?.header;
          // 一覧更新レコード
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    customerCode: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              if (response.data.contents) {
                this.gridApi.forEachNode((row) => {
                  response.data.contents.customers.forEach((customer) => {
                    let isError = this.errorRows.some((r) => r.customerCode === row.data.customerCode);
                    if (customer.customerCode == row.data.customerCode && !isError) {
                      this.updateSuccessRecords.push(customer);
                      updateRows.push(customer);
                    }
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`取引先情報の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`取引先情報を更新しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("onEditSubmit", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("customerBulkEdit_update::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.loadingOff();
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    requestFormat(row) {
      row.customerName = String(row.customerName);
      row.zipCode = String(row.zipCode);
      row.prefecture = String(row.prefecture);
      row.locationAddress = String(row.locationAddress);
      row.locationBuilding = String(row.locationBuilding);
      row.telNumber = String(row.telNumber);
      row.faxNumber = String(row.faxNumber);
      row.customerStaff = String(row.customerStaff);
      row.inCompanyOrderMaxAmount = CommonUtils.isNullOrEmpty(row.inCompanyOrderMaxAmount)
        ? null
        : Number(row.inCompanyOrderMaxAmount);
      row.customerStatus = Number(row.customerStatus);
      row.remarks = String(row.remarks);
      row.warehouseRemarks = String(row.warehouseRemarks);
      row.invoiceZipCode = String(row.invoiceZipCode);
      row.invoiceAddress = String(row.invoiceAddress);
      row.invoicePhoneNumber = String(row.invoicePhoneNumber);
      row.invoiceStaff = String(row.invoiceStaff);
      row.invoiceMailingAddress = String(row.invoiceMailingAddress);
      row.ourSalesStaff = String(row.ourSalesStaffCd);
      row.assistant = row.assistantCd;
      row.useOricon = CommonUtils.isTrueOrFalse(row.useOricon);
      row.forecast = CommonUtils.isTrueOrFalse(row.forecast);
      row.autoShipRequest = CommonUtils.isTrueOrFalse(row.autoShipRequest);
      row.invoiceApproval = CommonUtils.isTrueOrFalse(row.invoiceApproval);
      row.janDisplay = CommonUtils.isTrueOrFalse(row.janDisplay);
      return row;
    },
    onCellValueChanged(params) {
      console.log("params:");
      console.log(params);
      // セルの色を変えるため項目更新をチェック
      if (params.colDef.field == "customerName") {
        params.data.updateCustomerName = true;
      } else if (params.colDef.field == "zipCode") {
        params.data.updateZipCode = true;
      } else if (params.colDef.field == "prefecture") {
        params.data.updatePrefecture = true;
      } else if (params.colDef.field == "locationAddress") {
        params.data.updateLocationAddress = true;
      } else if (params.colDef.field == "locationBuilding") {
        params.data.updateLocationBuilding = true;
      } else if (params.colDef.field == "telNumber") {
        params.data.updateTelNumber = true;
      } else if (params.colDef.field == "faxNumber") {
        params.data.updateFaxNumber = true;
      } else if (params.colDef.field == "customerStaff") {
        params.data.updateCustomerStaff = true;
      } else if (params.colDef.field == "inCompanyOrderMaxAmount") {
        params.data.updateInCompanyOrderMaxAmount = true;
      } else if (params.colDef.field == "customerStatus") {
        params.data.updateCustomerStatus = true;
      } else if (params.colDef.field == "shipmentDayOfWeek") {
        params.data.updateShipmentDayOfWeek = true;
      } else if (params.colDef.field == "useOricon") {
        params.data.updateUseOricon = true;
      } else if (params.colDef.field == "forecast") {
        params.data.updateForecast = true;
      } else if (params.colDef.field == "autoShipRequest") {
        params.data.updateAutoShipRequest = true;
      } else if (params.colDef.field == "remarks") {
        params.data.updateRemarks = true;
      } else if (params.colDef.field == "warehouseRemarks") {
        params.data.updateWarehouseRemarks = true;
      } else if (params.colDef.field == "invoiceApproval") {
        params.data.updateInvoiceApproval = true;
      } else if (params.colDef.field == "invoiceZipCode") {
        params.data.updateInvoiceZipCode = true;
      } else if (params.colDef.field == "invoiceAddress") {
        params.data.updateInvoiceAddress = true;
      } else if (params.colDef.field == "invoicePhoneNumber") {
        params.data.updateInvoicePhoneNumber = true;
      } else if (params.colDef.field == "invoiceStaff") {
        params.data.updateInvoiceStaff = true;
      } else if (params.colDef.field == "invoiceMailingAddress") {
        params.data.updateInvoiceMailingAddress = true;
      } else if (params.colDef.field == "ourSalesStaffCd") {
        params.data.updateOurSalesStaff = true;
      } else if (params.colDef.field == "assistantCd") {
        params.data.updateAssistant = true;
      } else if (params.colDef.field == "janDisplay") {
        params.data.updateJanDisplay = true;
      }

      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
    },
    onSettingColSelected(index, col) {
      if (col === this.constant.customerBulkEditColumns.ASSISTANT) {
        // コンポーネント側で型エラーがでるので仕方なしに
        this.settingSources[index].value = [];
      } else {
        this.settingSources[index].value = "";
      }
    },
    onAddSettingSource() {
      let settingSource = {
        column: 0,
        value: "",
      };
      this.settingSources.push(settingSource);
    },
    onDelSettingSource(index) {
      this.settingSources.splice(index, 1);
    },
    onBtnSetUpdate() {
      this.loadingOn();
      if (this.validate()) {
        this.selectedRows.forEach((row) => {
          this.settingSources.forEach((source) => {
            console.log(source);
            if (source.column === this.constant.customerBulkEditColumns.CUSTOMER_NAME) {
              row.customerName = source.value;
              row.updateCustomerName = true;
            } else if (source.column === this.constant.customerBulkEditColumns.ZIP_CODE) {
              row.zipCode = source.value;
              row.updateZipCode = true;
            } else if (source.column === this.constant.customerBulkEditColumns.PREFECTURE) {
              row.prefecture = source.value;
              row.updatePrefecture = true;
            } else if (source.column === this.constant.customerBulkEditColumns.LOCATION_ADDRESS) {
              row.locationAddress = source.value;
              row.updateLocationAddress = true;
            } else if (source.column === this.constant.customerBulkEditColumns.LOCATION_BUILDING) {
              row.locationBuilding = source.value;
              row.updateLocationBuilding = true;
            } else if (source.column === this.constant.customerBulkEditColumns.TEL_NUMBER) {
              row.telNumber = source.value;
              row.updateTelNumber = true;
            } else if (source.column === this.constant.customerBulkEditColumns.FAX_NUMBER) {
              row.faxNumber = source.value;
              row.updateFaxNumber = true;
            } else if (source.column === this.constant.customerBulkEditColumns.CUSTOMER_STAFF) {
              row.customerStaff = source.value;
              row.updateCustomerStaff = true;
            } else if (source.column === this.constant.customerBulkEditColumns.IN_COMPANY_ORDER_MAX_AMOUNT) {
              row.inCompanyOrderMaxAmount = source.value;
              row.updateInCompanyOrderMaxAmount = true;
            } else if (source.column === this.constant.customerBulkEditColumns.CUSTOMER_STATUS) {
              row.customerStatus = source.value;
              row.updateCustomerStatus = true;
            } else if (source.column === this.constant.customerBulkEditColumns.SHIPMENT_DAY_OF_WEEK) {
              row.shipmentDayOfWeek = source.value;
              row.updateShipmentDayOfWeek = true;
            } else if (source.column === this.constant.customerBulkEditColumns.USE_ORICON) {
              row.useOricon = CommonUtils.isTrueOrFalse(source.value);
              row.updateUseOricon = true;
            } else if (source.column === this.constant.customerBulkEditColumns.FORECAST) {
              row.forecast = CommonUtils.isTrueOrFalse(source.value);
              row.updateForecast = true;
            } else if (source.column === this.constant.customerBulkEditColumns.AUTO_SHIP_REQUEST) {
              row.autoShipRequest = CommonUtils.isTrueOrFalse(source.value);
              row.updateAutoShipRequest = true;
            } else if (source.column === this.constant.customerBulkEditColumns.REMARKS) {
              row.remarks = source.value;
              row.updateRemarks = true;
            } else if (source.column === this.constant.customerBulkEditColumns.WAREHOUSE_REMARKS) {
              row.warehouseRemarks = source.value;
              row.updateWarehouseRemarks = true;
            } else if (source.column === this.constant.customerBulkEditColumns.INVOICE_APPROVAL) {
              row.invoiceApproval = CommonUtils.isTrueOrFalse(source.value);
              row.updateInvoiceApproval = true;
            } else if (source.column === this.constant.customerBulkEditColumns.INVOICE_ZIP_CODE) {
              row.invoiceZipCode = source.value;
              row.updateInvoiceZipCode = true;
            } else if (source.column === this.constant.customerBulkEditColumns.INVOICE_ADDRESS) {
              row.invoiceAddress = source.value;
              row.updateInvoiceAddress = true;
            } else if (source.column === this.constant.customerBulkEditColumns.INVOICE_PHONE_NUMBER) {
              row.invoicePhoneNumber = source.value;
              row.updateInvoicePhoneNumber = true;
            } else if (source.column === this.constant.customerBulkEditColumns.INVOICE_STAFF) {
              row.invoiceStaff = source.value;
              row.updateInvoiceStaff = true;
            } else if (source.column === this.constant.customerBulkEditColumns.INVOICE_MAILING_ADDRESS) {
              row.invoiceMailingAddress = source.value;
              row.updateInvoiceMailingAddress = true;
            } else if (source.column === this.constant.customerBulkEditColumns.OUR_SALES_STAFF) {
              row.ourSalesStaffCd = source.value;
              row.updateOurSalesStaff = true;
            } else if (source.column === this.constant.customerBulkEditColumns.ASSISTANT) {
              row.assistantCd = source.value;
              row.updateAssistant = true;
            } else if (source.column === this.constant.customerBulkEditColumns.JAN_DISPLAY) {
              row.janDisplay = CommonUtils.isTrueOrFalse(source.value);
              row.updateJanDisplay = true;
            }
          });
        });
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
      }
      this.loadingOff();
    },
    // ****************************
    // 共通業務処理
    // ****************************
    computeCustomerItems(row) {
      row.customerPcsQuantity = row.inBoxNumber * row.customerQuantity;
      if (row.retailPrice > row.unitPrice) {
        row.customerRate = Math.round((row.unitPrice / row.retailPrice) * 100);
      }
      row.customerPriceTotal = row.unitPrice * row.customerQuantity;
    },

    // ****************************
    // Validation定義
    // ****************************
    validate() {
      const isValid = this.$refs.customerBulkEditForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    async validateRow(row) {
      var ret = true;
      var messages = [];

      this.setValidMessage(this.rules.required(row.customerName), "取引先名", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.customerName), "取引先名", messages);
      this.setValidMessage(this.rules.zipCode(row.zipCode), "郵便番号", messages);
      this.setValidMessage(await this.rules.isDeliveryZipArea(row.zipCode), "郵便番号", messages);
      this.setValidMessage(this.rules.required(row.prefecture), "都道府県", messages);
      this.setValidMessage(this.rules.maxLength(4)(row.prefecture), "都道府県", messages);
      this.setValidMessage(this.rules.required(row.locationAddress), "所在地（住所）", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.locationAddress), "所在地（住所）", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.locationBuilding), "所在地（ビル名）", messages);
      this.setValidMessage(this.rules.phoneNo(row.telNumber), "電話番号", messages);
      this.setValidMessage(this.rules.phoneNo(row.faxNumber), "FAX番号", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.customerStaff), "担当者", messages);
      this.setValidMessage(this.rules.isNumber(row.inCompanyOrderMaxAmount), "社内発注上限金額", messages);
      this.setValidMessage(this.rules.maxLength(13)(row.inCompanyOrderMaxAmount), "社内発注上限金額", messages);
      this.setValidMessage(this.rules.required(row.customerStatus), "ステータス", messages);
      this.setValidMessage(this.rules.required(row.useOricon), "オリコン指定", messages);
      this.setValidMessage(this.rules.required(row.forecast), "フォーキャスト可否", messages);
      this.setValidMessage(this.rules.required(row.autoShipRequest), "自動出荷希望", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.remarks), "備考", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.warehouseRemarks), "倉庫備考", messages);

      this.setValidMessage(this.rules.required(row.invoiceApproval), "請求書発行可否", messages);
      this.setValidMessage(this.rules.zipCode(row.invoiceZipCode), "請求先〒", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.invoiceAddress), "請求先住所", messages);
      this.setValidMessage(this.rules.phoneNo(row.invoicePhoneNumber), "請求先TEL", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.invoiceStaff), "請求担当者", messages);
      this.setValidMessage(this.rules.maxLength(150)(row.invoiceMailingAddress), "請求書宛名", messages);

      this.setValidMessage(this.rules.required(row.ourSalesStaffCd), "営業担当", messages);
      this.setValidMessage(this.rules.required(row.janDisplay), "JAN表示", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY-MM-DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    validSelectColumn(value) {
      if (value == null) return true;
      if (this.settingSources.filter((settingSource) => settingSource.column === value).length > 1)
        return "同一項目が選択されています。";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    setCustomValidMessage(check, messages) {
      if (!(check === true)) messages.push(check);
    },
    onCustomerPersonChanged() {
      //NOP
    },
  },

  async beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = await this.setColumnDefs();
  },
};
</script>
