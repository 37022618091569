<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-search</v-icon>統一掛率一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <tooltip-icon-button v-if="allowedAction('C070602')" icon="mdi-database-import" @click="onBtnImport"
          >一括取込</tooltip-icon-button
        >
        <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
          <rate-setting-import
            @complete="onImportComplete"
            @expand="importDialog.width = $event ? '100%' : '800px'"
            v-if="importDialog.show"
          ></rate-setting-import>
        </v-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <tooltip-icon-button v-if="allowedAction('C070603')" icon="mdi-download" @click="onBtnExport">
          CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="RateSettingList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="RateSettingInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { statuses as ApiStatus } from "../../libs/api-client";
import RateSettingImport from "../../components/rateSetting/RateSettingImport.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import {
  FullDateColumn,
  CheckmarkColumn,
  DateColumn,
  IncludeFilter,
  PercentColumn,
} from "../../models/ag-grid/columnTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { typeFormat } from "../../models/ag-grid/helpers";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import Validation from "../../libs/validation";

export default {
  name: "RateSettingSearch",
  components: {
    AgGridVue,
    RateSettingImport,
    TooltipIconButton,
    ErrorGridDialog,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      gridApi: null,
      columnApi: null,
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isCode: Validation.isCode,
      },
      defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      constants: {
        cafereoActions: ["C070402", "C070403"],
      },
      columnDefs: [],
      defaultCsvExportParams: {
        allColumns: false,
        onlySelectedAllPages: false,
      },
      rowData: [],
      detailSelected: false,
      infoMaximum: false,
      selectedPageSize: null,
      localeText: AG_GRID_LOCALE_JA,
      selectionRows: [],
      searchModel: {},
      editDialog: { show: false, width: "800px" },
      importDialog: { show: false, width: "800px" },
      columnTypes: {
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpPercentColumn: PercentColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      cafereoColumnDefs: [
        { headerName: "仕入先法人CD", field: "supplierCorpCd" },
        { headerName: "仕入先名", field: "corporationName" },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        { headerName: "掛率", field: "rate", type: "dpPercentColumn", filter: "agNumberColumnFilter" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
    };
  },
  beforeMount() {
    this.selectedPageSize = this.globalPageSize;
    if (this.isCafereoUser) this.columnDefs = this.columnDefs.concat(this.cafereoColumnDefs);
    if (this.isVendorUser) this.columnDefs = this.columnDefs.concat(this.vendorColumnDefs);
  },
  async mounted() {
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    console.log("this.gridHeightSize" + this.gridHeightSize);
    this.gridStyle.height = this.gridHeightSize - 10 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv({
        allColumns: false,
        suppressQuotes: true,
        onlySelected: this.selectionRows.length > 0,
        fileName: `統一掛率一覧`,
        columnKeys: ["supplierCorpCd", "jancode", "rate"],
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("rateSetting/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "統一掛率一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.orderRates).length === 0) {
          this.$dialog.warning({
            title: "統一掛率一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(response.data.contents.orderRates);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("RateSettingSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    onBtnImport() {
      this.importDialog.show = true;
    },
    onImportComplete(canceled) {
      this.importDialog.show = false;
      if (canceled) {
        this.onSearchClick();
      }
    },
  },
};
</script>
